.ant-table table {
  position: relative;
}
.ant-table-thead {
  position: inherit;
  z-index: 2;
}

.ant-card .recharts-cartesian-axis-tick-value {
  font-size: 11px !important;
}

@media screen and (max-width: 770px) {
  .charge-label {
    text-align: initial;
  }
}
